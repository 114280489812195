import {
  FieldType,
  type PanelOptionsEditorBuilder,
  type SelectableValue
} from '@grafana/data'
import type { PercentileOptions } from '../types'
import { getFieldName } from '../utils'

export function addPercentileOptions(
  builder: PanelOptionsEditorBuilder<PercentileOptions>
) {
  builder
    .addBooleanSwitch({
      path: 'percentile.showPercentile',
      name: 'Visibility',
      defaultValue: true
    })
    .addMultiSelect({
      path: 'percentile.series',
      name: 'Series with percentiles',
      settings: {
        options: [] as SelectableValue<string>[],
        async getOptions(context) {
          const options = context.data
            .flatMap((frame) => frame.fields)
            .filter((field) => field.type === FieldType.number)
            .map(
              (field) =>
                ({
                  label: getFieldName(field),
                  value: getFieldName(field)
                }) as SelectableValue<string>
            )

          return options
        }
      },
      description: 'Select series to show Nth percentile',
      showIf: (options) => options.percentile.showPercentile
    })
    .addNumberInput({
      path: 'percentile.percentile',
      defaultValue: 99,
      name: 'Percentile',
      settings: {
        integer: true,
        min: 0,
        max: 100
      },
      showIf: (options) => options.percentile.showPercentile
    })
}
