import {
  fieldReducers,
  FieldType,
  type PanelOptionsEditorBuilder
} from '@grafana/data'
import { commonOptionsBuilder } from '@grafana/ui'
import { PercentileOptions } from '../types'
import { calculatePercentile } from 'utils'

type FieldReducer = NonNullable<
  Parameters<typeof fieldReducers.register>[0]['reduce']
>

export function addLegendOptions(
  builder: PanelOptionsEditorBuilder<PercentileOptions>,
  includeLegendCalcs = true
) {
  commonOptionsBuilder.addLegendOptions(builder, includeLegendCalcs)

  const defaultPercentile: number = builder
    .getRegistry()
    .get('percentile.percentile').defaultValue!

  const reduce: FieldReducer = (field, ignoreNulls, nullAsZero) => {
    if (field.type !== FieldType.number) {
      return {}
    }

    const values = field.values.reduce((acc, v) => {
      if (v === null && ignoreNulls) {
        return acc
      }
      return [...acc, v === null && nullAsZero ? v : v]
    }, [])

    const percentile = field.config.custom.percentile ?? defaultPercentile
    return { percentile: calculatePercentile(values, percentile) }
  }

  const field = fieldReducers.getIfExists('percentile')
  if (field) {
    field.reduce = reduce
    return
  }

  fieldReducers.register({
    id: 'percentile',
    name: 'Effective',
    description: `Effective speed. Percentile as specified by the custom percentile option or ${defaultPercentile}th percentile by default`,
    standard: false,
    reduce
  })
}
