import type { PanelProps } from '@grafana/data'
import { PanelDataErrorView } from '@grafana/runtime'
import {
  TimeSeries,
  TooltipDisplayMode,
  TooltipPlugin,
  ZoomPlugin,
  usePanelContext,
  useTheme2
} from '@grafana/ui'
import React, { useMemo } from 'react'
import { PercentileOptions } from 'types'
import { getTimezones, prepareGraphableFields } from 'utils'
import { PercentilePlugin } from './PercentilePlugin'

type Props = PanelProps<PercentileOptions> & {}

export const PercentilePanel: React.FC<Props> = ({ options, ...props }) => {
  const { sync } = usePanelContext()
  const timezones = useMemo(
    () => getTimezones(options.timezone, props.timeZone),
    [options.timezone, props.timeZone]
  )

  const theme = useTheme2()
  const frames = useMemo(
    () =>
      prepareGraphableFields(
        props.data.series,
        theme,
        props.timeRange,
        undefined,
        options.percentile.percentile
      ),
    [props.data.series, props.timeRange, theme, options.percentile]
  )

  if (!frames) {
    return (
      <PanelDataErrorView
        panelId={props.id}
        fieldConfig={props.fieldConfig}
        data={props.data}
        needsTimeField={true}
        needsNumberField={true}
      />
    )
  }

  return (
    <TimeSeries
      frames={frames}
      structureRev={props.data.structureRev}
      timeRange={props.timeRange}
      timeZone={timezones}
      width={props.width}
      height={props.height}
      legend={options.legend}
      options={options}
    >
      {(config, alignedDataFrame) => (
        <>
          <ZoomPlugin config={config} onZoom={props.onChangeTimeRange} />
          {options.tooltip.mode === TooltipDisplayMode.None || (
            <TooltipPlugin
              data={alignedDataFrame}
              config={config}
              mode={options.tooltip.mode}
              sortOrder={options.tooltip.sort}
              sync={sync}
              timeZone={props.timeZone}
            />
          )}

          <PercentilePlugin
            options={options.percentile}
            config={config}
            data={alignedDataFrame}
            timeZone={props.timeZone}
          />
        </>
      )}
    </TimeSeries>
  )
}
