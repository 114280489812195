import { PanelPlugin } from '@grafana/data'
import { commonOptionsBuilder, GraphFieldConfig } from '@grafana/ui'
import { PercentilePanel } from 'components/PercentilePanel'
import { TimezonesEditor } from 'components/TimezonesEditor'
import { defaultGraphConfig, getGraphFieldConfig } from 'config'
import { timeseriesPanelChangedHandler } from 'migrations'
import { PercentileOptions } from 'types'
import * as percentileOptionsBuilder from './builder'

export const plugin = new PanelPlugin<PercentileOptions, GraphFieldConfig>(
  PercentilePanel
)
  .setPanelChangeHandler(timeseriesPanelChangedHandler)
  .useFieldConfig(getGraphFieldConfig(defaultGraphConfig))
  .setPanelOptions((builder) => {
    percentileOptionsBuilder.addPercentileOptions(builder)
    commonOptionsBuilder.addTooltipOptions(builder)
    percentileOptionsBuilder.addLegendOptions(builder)

    builder.addCustomEditor({
      id: 'timezone',
      name: 'Time zone',
      path: 'timezone',
      category: ['Axis'],
      editor: TimezonesEditor,
      defaultValue: undefined
    })
  })
  .setDataSupport({ annotations: false })
