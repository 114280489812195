import {
  DataFrame,
  FieldType,
  GrafanaTheme2,
  TimeRange,
  getDisplayProcessor,
  isBooleanUnit,
  type Field
} from '@grafana/data'
import { GraphFieldConfig, LineInterpolation } from '@grafana/ui'
// @ts-ignore
import { convertFieldType } from '@grafana/data/dist/esm/transformations/transformers/convertFieldType'
// @ts-ignore
import { applyNullInsertThreshold } from '@grafana/ui/dist/esm/graveyard/GraphNG/nullInsertThreshold'
import memoize from 'lodash/memoize'
import sortBy from 'lodash/sortBy'

export const calculatePercentile = memoize(
  (arr: number[], percentile: number) => {
    const sorted = sortBy(arr.filter((v) => v !== null))
    return sorted[Math.floor((percentile / 100.0) * (sorted.length - 1))]
  }
)

export function getFieldName(field: Field): string {
  return (
    field.config.displayName ??
    field.config.displayNameFromDS ??
    field.labels?.name ??
    'unknown'
  )
}

export function getTimezones(
  timezones: string[] | undefined,
  defaultTimezone: string
): string[] {
  if (!timezones || !timezones.length) {
    return [defaultTimezone]
  }
  return timezones.map((v) => (v?.length ? v : defaultTimezone))
}

export function prepareGraphableFields(
  series: DataFrame[],
  theme: GrafanaTheme2,
  timeRange?: TimeRange,
  // numeric X requires a single frame where the first field is numeric
  xNumFieldIdx?: number,
  percentile?: number
): DataFrame[] | null {
  if (!series?.length) {
    return null
  }

  let useNumericX = xNumFieldIdx != null

  // Make sure the numeric x field is first in the frame
  if (xNumFieldIdx != null && xNumFieldIdx > 0) {
    series = [
      {
        ...series[0],
        fields: [
          series[0].fields[xNumFieldIdx],
          ...series[0].fields.filter((f, i) => i !== xNumFieldIdx)
        ]
      }
    ]
  }

  // some datasources simply tag the field as time, but don't convert to milli epochs
  // so we're stuck with doing the parsing here to avoid Moment slowness everywhere later
  // this mutates (once)
  for (let frame of series) {
    for (let field of frame.fields) {
      if (
        field.type === FieldType.time &&
        typeof field.values[0] !== 'number'
      ) {
        field.values = convertFieldType(field, {
          destinationType: FieldType.time
        }).values
      }
    }
  }

  // let enumFieldsCount = 0

  // loopy: for (let frame of series) {
  //   for (let field of frame.fields) {
  //     if (field.type === FieldType.enum && ++enumFieldsCount > 1) {
  //       series = reEnumFields(series)
  //       break loopy
  //     }
  //   }
  // }

  let copy: Field

  const frames: DataFrame[] = []

  for (let frame of series) {
    const fields: Field[] = []

    let hasTimeField = false
    let hasValueField = false

    let nulledFrame = useNumericX
      ? frame
      : (applyNullInsertThreshold({
          frame,
          refFieldPseudoMin: timeRange?.from.valueOf(),
          refFieldPseudoMax: timeRange?.to.valueOf()
        }) as DataFrame)

    const frameFields = nullToValue(nulledFrame).fields

    for (let fieldIdx = 0; fieldIdx < frameFields?.length ?? 0; fieldIdx++) {
      const field = frameFields[fieldIdx]

      switch (field.type) {
        case FieldType.time:
          hasTimeField = true
          fields.push(field)
          break
        case FieldType.number:
          hasValueField = useNumericX ? fieldIdx > 0 : true
          copy = {
            ...field,
            values: field.values.map((v) => {
              if (!(Number.isFinite(v) || v == null)) {
                return null
              }
              return v
            })
          }

          fields.push(copy)
          break // ok
        case FieldType.enum:
          hasValueField = true
        case FieldType.string:
          copy = {
            ...field,
            values: field.values
          }

          fields.push(copy)
          break // ok
        case FieldType.boolean:
          hasValueField = true
          const custom: GraphFieldConfig = field.config?.custom ?? {}
          const config = {
            ...field.config,
            max: 1,
            min: 0,
            custom
          }

          // smooth and linear do not make sense
          if (custom.lineInterpolation !== LineInterpolation.StepBefore) {
            custom.lineInterpolation = LineInterpolation.StepAfter
          }

          copy = {
            ...field,
            config,
            type: FieldType.number,
            values: field.values.map((v) => {
              if (v == null) {
                return v
              }
              return Boolean(v) ? 1 : 0
            })
          }

          if (!isBooleanUnit(config.unit)) {
            config.unit = 'bool'
            copy.display = getDisplayProcessor({ field: copy, theme })
          }

          fields.push(copy)
          break
      }
    }

    if ((useNumericX || hasTimeField) && hasValueField) {
      frames.push({
        ...frame,
        length: nulledFrame.length,
        fields
      })
    }
  }

  if (frames.length) {
    if (percentile != null) {
      setPercentile(frames, percentile)
    }
    setClassicPaletteIdxs(frames, theme, 0)
    matchEnumColorToSeriesColor(frames, theme)
    return frames
  }

  return null
}

function setPercentile(frames: DataFrame[], percentile: number) {
  for (const frame of frames) {
    for (const field of frame.fields) {
      if (field.type === FieldType.number) {
        field.config.custom.percentile = percentile
      }
    }
  }
}

function matchEnumColorToSeriesColor(
  frames: DataFrame[],
  theme: GrafanaTheme2
) {
  const { palette } = theme.visualization
  for (const frame of frames) {
    for (const field of frame.fields) {
      if (field.type === FieldType.enum) {
        const namedColor = palette[field.state?.seriesIndex! % palette.length]
        const hexColor = theme.visualization.getColorByName(namedColor)
        const enumConfig = field.config.type!.enum!

        enumConfig.color = Array(enumConfig.text!.length).fill(hexColor)
        field.display = getDisplayProcessor({ field, theme })
      }
    }
  }
}

function setClassicPaletteIdxs(
  frames: DataFrame[],
  theme: GrafanaTheme2,
  skipFieldIdx?: number
) {
  let seriesIndex = 0
  frames.forEach((frame) => {
    frame.fields.forEach((field, fieldIdx) => {
      if (
        fieldIdx !== skipFieldIdx &&
        (field.type === FieldType.number ||
          field.type === FieldType.boolean ||
          field.type === FieldType.enum)
      ) {
        field.state = {
          ...field.state,
          seriesIndex: seriesIndex++ // TODO: skip this for fields with custom renderers (e.g. Candlestick)?
        }
        field.display = getDisplayProcessor({ field, theme })
      }
    })
  })
}

function nullToValue(frame: DataFrame) {
  return {
    ...frame,
    fields: frame.fields.map((field) => {
      const noValue = +field.config?.noValue!

      if (!Number.isNaN(noValue)) {
        const transformedVals = field.values.slice()

        for (let i = 0; i < transformedVals.length; i++) {
          if (transformedVals[i] === null) {
            transformedVals[i] = noValue
          }
        }

        return {
          ...field,
          values: transformedVals
        }
      } else {
        return field
      }
    })
  }
}
