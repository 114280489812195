import { DataFrame, FieldType, getDisplayProcessor } from '@grafana/data'
import { useTheme2, type UPlotConfigBuilder } from '@grafana/ui'
import React, { useLayoutEffect, useRef } from 'react'
import { PercentileOptions } from 'types'
import { calculatePercentile, getFieldName } from 'utils'

type Props = {
  options: PercentileOptions['percentile']
  data: DataFrame
  config: UPlotConfigBuilder
  timeZone: string
}

const PercentilePlugin: React.FC<Props> = (props) => {
  const { config, options, timeZone } = props
  const theme = useTheme2()

  // using a ref to store the current data frame so the addHook callback can have a reference to it
  const data = useRef(props.data)

  useLayoutEffect(() => {
    data.current = props.data
    const { percentile, showPercentile = false, series = [] } = options
    if (!showPercentile || percentile === undefined || series.length === 0) {
      return
    }

    config.addHook('draw', (u) => {
      for (let index = 0; index < data.current.fields.length; index++) {
        const field = data.current.fields[index]
        if (
          field.type !== FieldType.number ||
          !series.includes(getFieldName(field))
        ) {
          continue
        }

        const percentileValue = calculatePercentile(field.values, percentile)
        const display = getDisplayProcessor({ field, timeZone, theme })(
          field.values[0]
        )

        const inverted = field.config.custom.transform === 'negative-Y'
        const yValue = inverted ? -percentileValue : percentileValue

        renderLine(
          {
            value: yValue,
            color:
              display.color ??
              field.config.color?.fixedColor ??
              theme.colors.primary.main,
            series: u.series[index]
          },
          u
        )
      }
    })
  }, [config, options, props.data, timeZone, theme])

  return null
}

type Line = {
  value: number
  color: string
  series: uPlot.Series
}

function renderLine(line: Line, plotter: uPlot) {
  const { series, color, value } = line
  if (!series.show) {
    return
  }

  const { ctx, bbox } = plotter
  const y = plotter.valToPos(value, series.scale ?? 'y', true)
  if (y < bbox.top || y > bbox.top + bbox.height) {
    return
  }

  ctx.beginPath()
  ctx.strokeStyle = color
  ctx.lineWidth = 4
  ctx.moveTo(bbox.left, y)
  ctx.lineTo(bbox.width + bbox.left, y)
  ctx.stroke()
  ctx.closePath()
}

export { PercentilePlugin }
